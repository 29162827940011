<script setup lang="ts">
import { z } from 'zod';
import type { FormSubmitEvent } from '#ui/types';

const form = ref();

const schema = z.object({
	email: z.string().email('Invalid email'),
	password: z.string().min(8, 'Must be at least 8 characters'),
});

type Schema = z.output<typeof schema>;

const state = reactive<Schema>({
	email: '',
	password: '',
});

interface ActionState {
	pending: boolean;
	error: string | null;
}

const actionState = reactive<ActionState>({
	pending: false,
	error: null,
});

const isValid = computed(() => {
	try {
		schema.parse(state);
		return true;
	}
	catch {
		return false;
	}
});

// redirect query param
const route = useRoute();
const redirect = route.query.redirect as string | undefined;

const { logIn } = useAuthStore();

const loginErrors: { [key: number]: string } = {
	400: 'Invalid email or password',
	401: 'Invalid email or password',
};

async function onSubmit(event: FormSubmitEvent<Schema>) {
	try {
		actionState.pending = true;
		await logIn(event.data.email, event.data.password);
		// redirect to the previous page or home
		navigateTo(redirect || '/');
	}
	catch (error: any) {
		actionState.error = loginErrors[error.status] || 'An error occurred';
	}
	finally {
		actionState.pending = false;
	}
}
</script>

<template>
	<div class="min-w-0 sm:min-w-72 sm:max-w-72 space-y-4">
		<div class="flex w-full items-center justify-center gap-2">
			<UIcon
				name="i-heroicons-lock-closed"
				class="text-xl"
			/>
			<p class="text-center text-2xl text-gray-900 dark:text-white font-bold">
				Log In
			</p>
		</div>

		<p class="text-center text-sm text-gray-500 dark:text-gray-400">
			Membership System
		</p>

		<UForm
			ref="form"
			:schema="schema"
			:state="state"
			class="space-y-4"
			@submit="onSubmit"
		>
			<UFormGroup
				label="Email"
				name="email"
			>
				<UInput
					v-model="state.email"
					placeholder="you@example.com"
					icon="i-heroicons-envelope"
					input-class="bg-white dark:bg-gray-900"
					autocomplete="username"
				/>
			</UFormGroup>

			<UFormGroup
				label="Password"
				name="password"
			>
				<UInput
					v-model="state.password"
					type="password"
					placeholder="**********"
					icon="i-heroicons-lock-closed"
					input-class="bg-white dark:bg-gray-900"
					autocomplete="current-password"
				/>
				<template #hint>
					<NuxtLink
						to="/password/request"
						class="text-primary font-medium hover:underline"
					>Forgot password?</NuxtLink>
				</template>
			</UFormGroup>

			<UAlert
				v-if="actionState.error"
				color="red"
				variant="soft"
				:description="actionState.error"
				class="p-1 text-center"
			/>

			<UButton
				type="submit"
				block
				trailing-icon="i-heroicons-arrow-right"
				:disabled="!isValid"
				:loading="actionState.pending"
			>
				Log In
			</UButton>
		</UForm>
	</div>
</template>
